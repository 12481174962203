<template>
  <main class="stat-tracker-container" v-if="loaded">
    <header><h4>The Stat Tracker</h4></header>
    <ul class="player-results">
      <li class="result" v-for="hole in holeOrder" :key="hole.id">
        <span class="left">
          <div class="hole-number">{{ hole.number }}</div>
          <div
            class="score"
            :style="resultParColor(hole, resultToDisplay(hole).score)"
          >
            {{ resultToDisplay(hole).score }}
          </div>
        </span>
        <span class="right">
          <div class="circle-hit">
            <img
              src="@/assets/img/circle-hit.svg"
              :class="{ selected: resultToDisplay(hole).isCircleHit }"
            />
          </div>
          <div class="inside">
            <img
              src="@/assets/img/inside-putt.svg"
              :class="{ selected: resultToDisplay(hole).isInsidePutt }"
            />
          </div>
          <div class="outside">
            <img
              src="@/assets/img/outside-putt.svg"
              :class="{ selected: resultToDisplay(hole).isOutsidePutt }"
              @click="statsManager('isOutsidePutt')"
            />
          </div>
          <div class="ob">
            <img
              src="@/assets/img/ob.svg"
              :class="{ selected: resultToDisplay(hole).isOutOfBounds }"
              @click="statsManager('isOutOfBounds')"
            />
          </div>
        </span>
      </li>
    </ul>
  </main>
</template>

<script>
export default {
  name: "ThePersonalStatTracker",
  components: {},
  data() {
    return {
      loaded: false,
      groupData: null,
    };
  },
  computed: {
    holeOrder() {
      let holeOrder = [];
      let beforeStartHole = [];
      let afterStartHole = [];
      let startHole = {};

      for (var i = 0; i < this.groupData.pool.layoutVersion.holes.length; i++) {
        if (
          this.groupData.pool.layoutVersion.holes[i].number ==
          this.groupData.startHole.number
        ) {
          startHole = this.groupData.pool.layoutVersion.holes[i];
        } else if (
          this.groupData.pool.layoutVersion.holes[i].number <
          this.groupData.startHole.number
        ) {
          beforeStartHole.push(this.groupData.pool.layoutVersion.holes[i]);
        } else if (
          this.groupData.pool.layoutVersion.holes[i].number >
          this.groupData.startHole.number
        ) {
          afterStartHole.push(this.groupData.pool.layoutVersion.holes[i]);
        }
      }

      return holeOrder.concat(startHole, afterStartHole, beforeStartHole);
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    usersScores() {
      return this.groupData.results.filter(
        (player) =>
          player.playerConnection.player.userId ==
          this.userInfo.sessionInfo.userId
      );
    },
  },
  watch: {},
  methods: {
    resultParColor(holePar, result) {
      if (result != 0) {
        if (result == 1) {
          return "color: " + this.$store.state.colors.parColors["ace"].hex;
        } else if (result - holePar.par <= -3) {
          return (
            "color: " + this.$store.state.colors.parColors["albatross"].hex
          );
        } else if (result - holePar.par == -2) {
          return "color: " + this.$store.state.colors.parColors["eagle"].hex;
        } else if (result - holePar.par == -1) {
          return "color: " + this.$store.state.colors.parColors["birdie"].hex;
        } else if (result - holePar.par == 0) {
          return "color: " + this.$store.state.colors.parColors["par"].hex;
        } else if (result - holePar.par == 1) {
          return "color: " + this.$store.state.colors.parColors["bogey"].hex;
        } else if (result - holePar.par == 2) {
          return (
            "color: " + this.$store.state.colors.parColors["doubleBogey"].hex
          );
        } else {
          return (
            "color: " + this.$store.state.colors.parColors["tripleBogey"].hex
          );
        }
      }
    },
    resultToDisplay(hole) {
      let result = this.usersScores.filter(
        (result) => hole.id == result.hole.id
      );

      if (result.length == 1) {
        return result[0];
      } else
        return {
          hole: {},
          id: "",
          isCircleHit: false,
          isInsidePutt: false,
          isOutsidePutt: false,
          isOutOfBounds: false,
          score: "",
        };
    },
    showHoleNameOrNumber(hole) {
      if (hole.name) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    async fetchScores() {
      this.$store.dispatch("authCheck");

      try {
        let groupData = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          {
            group(groupId:"${this.$router.currentRoute.params.groupId}"){
              startHole{
                id
                number
              }
              pool{
                status
                id
                round{
                  event{
                    id
                    isResultSigningEnabled
                    isVerifyMode
                  }
                }
                layoutVersion{
                  holes{
                    id
                    number
                    par
                    name
                  }
                }
              }
              playerConnections{
                player{
                  userId
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
              results(isVerified:false){
                id
                score
                isCircleHit
                isOutsidePutt
                isInsidePutt
                isOutOfBounds
                createdByUserId
                updatedByUserId
                hash
                isVerified
                playerConnection{
                  player{
                    userId
                    user{
                      id
                      firstName
                      lastName
                    }
                  }
                }
                hole{
                  id
                  number
                  par
                }
              }
            }
          }
          `,
          },
        });

        this.groupData = groupData.data.data.group;
        this.loaded = true;

        if (groupData.data.data.group.pool.status == "COMPLETED") {
          this.$router.push({ name: "scorecard-picker" });
        }
      } catch (err) {
        this.$router.push({ name: "scorecard-picker" });
      }
    },
  },
  beforeMount() {
    this.fetchScores();
  },
};
</script>
<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-bottom: 1px solid $strom;
  h4 {
    font-size: 20px;
    color: white;
    text-align: center;
    @include Gilroy-Bold;
    margin: 0;
  }
}

.player-results {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 0px 15px;
  .result {
    padding: 24px 0;
    border-bottom: 1px solid $strom;
    display: flex;

    .left {
      width: 30%;
      display: flex;
      align-items: center;
      .hole-number {
        width: 15%;
        text-align: center;
        line-height: 100%;
        font-size: 24px;
        color: $strom;
        @include Gilroy-Light;
      }
      .score {
        width: 85%;
        text-align: center;
        line-height: 100%;
        font-size: 64px;
        @include Gilroy-Bold;
      }
    }
    .right {
      width: 70%;
      display: flex;
      justify-content: space-between;
      img {
        height: 64px;
        opacity: 0.15;
        transition: all ease 0.3s;

        &.selected {
          opacity: 1;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 1200px) {
}
</style>
